/**
 * Template broadcast text using this template
 * https://docs.google.com/document/d/1HtS6aBlI5BfvaaG4G5MGvRhljaxIlrAAGsSOecutXQ0/edit
 */
export const MESSAGE = {
  // for Bahasa Indonesia invitation - general
  ID_GENERAL_INVITATION: `Yth. Bapak/Ibu/Saudara/i,  
*{{GUEST_NAME}}*

*Tanpa mengurangi rasa hormat, mohon klik link dibawah ini untuk mendapatkan informasi lengkap mengenai acara pernikahan kami:*
{{LINK}}
_*) Alasan keamanan WhatsApp, jika link tidak clickable (tidak berwarna biru), silakan untuk coba klik "Continue / Lanjutkan", balas "YA", atau simpan nomor ini untuk dapat membuka link._

Bersama rahmat dan berkat Tuhan yang telah menyatukan kami, pada hari ini kami berniat ingin mengundang Bapak/Ibu/Saudara/i untuk hadir dan turut memberikan doa secara langsung dalam acara kami: 

*{{BOY_NAME}}*
_Putra dari Bapak {{BOY_FATHER_NAME}} dan Ibu {{BOY_MOTHER_NAME}}_
& 
*{{GIRL_NAME}}*
_Putri dari Bapak {{GIRL_FATHER_NAME}} dan Ibu {{GIRL_MOTHER_NAME}}_

Acara pernikahan kami akan dilaksanakan pada hari *{{BROADCAST_WEDDING_DAY}}* di {{BROADCAST_WEDDING_LOCATION}}.

Merupakan suatu kehormatan bagi kami apabila Bapak/Ibu/Saudara/i berkenan hadir dan mendoakan secara langsung dalam acara pernikahan kami. 

Sampai jumpa di acara pernikahan kami!

_Kami yang berbahagia,_
*{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & keluarga*`,

  // for English invitation - general
  EN_GENERAL_INVITATION: `Dear Mr/Ms/Mrs, 
*{{GUEST_NAME}}* 

Please click the Website Invitation link below for more information about our wedding details: 
{{LINK}}
_*) Due to WhatsApp security reasons, if the link is not clickable (the color is not blue), please try to click "Continue / Lanjutkan", reply "YES", or save this number to open the link._

Together with the grace and blessing of God, we cordially request the honour of your presence at the wedding celebration of: 

*{{BOY_NAME}}*
_The son of Mr. {{BOY_FATHER_NAME}} and Mrs. {{BOY_MOTHER_NAME}}_
& 
*{{GIRL_NAME}}* 
_The daughter of Mr. {{GIRL_FATHER_NAME}} and Mrs. {{GIRL_MOTHER_NAME}}_

Together with the families, we will celebrate the wedding on *{{BROADCAST_WEDDING_DAY}}* at {{BROADCAST_WEDDING_LOCATION}}. 

See you on our wedding day!

_With love,_
*{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & family*`,

  // for Bahasa Indonesia invitation - muslim
  ID_MUSLIM_INVITATION: `Bismillahirrahmanirrahim 
Assalamualaikum warahmatullahi wabarakatuh 

Yth. Bapak/Ibu/Saudara/i,  
*{{GUEST_NAME}}* 

Tanpa mengurangi rasa hormat, mohon klik link dibawah ini untuk mendapatkan informasi lengkap mengenai acara pernikahan kami:
{{LINK}}
_*) Alasan keamanan WhatsApp, jika link tidak clickable (tidak berwarna biru), silakan untuk coba klik "Continue / Lanjutkan", balas "YA", atau simpan nomor ini untuk dapat membuka link._

Dengan memohon rahmat dan ridho Allah Subhanahu wa Ta’ala, kami berniat ingin mengundang Bapak/Ibu/Saudara/i untuk hadir dan turut memberikan doa restu secara langsung dalam acara kami: 

*{{BOY_NAME}}*
_Putra dari Bapak {{BOY_FATHER_NAME}} dan Ibu {{BOY_MOTHER_NAME}}_
& 
*{{GIRL_NAME}}* 
_Putri dari Bapak {{GIRL_FATHER_NAME}} dan Ibu {{GIRL_MOTHER_NAME}}_

Insha Allah acara pernikahan kami akan dilaksanakan pada hari *{{BROADCAST_WEDDING_DAY}}* di {{BROADCAST_WEDDING_LOCATION}}.

Merupakan suatu kehormatan bagi kami apabila Bapak/Ibu/Saudara/i berkenan hadir dan mendoakan secara langsung dalam acara pernikahan kami. 

Wassalamualaikum Warahmatullahi Wabarakatuh 

_Kami yang berbahagia,_
*{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & keluarga*`,

  // for English Invitation - muslim
  EN_MUSLIM_INVITATION: `Bismillahirrahmanirrahim 
Assalamualaikum warahmatullahi wabarakatuh 

Dear Mr/Ms/Mrs, 
*{{GUEST_NAME}}* 

Please click the Website Invitation link below for more information about our wedding details: 
{{LINK}}
_*) Due to WhatsApp security reasons, if the link is not clickable (the color is not blue), please try to click "Continue / Lanjutkan", reply "YES", or save this number to open the link._

Together with the blessing of Allah Subhanahu wa Ta’ala, we cordially request the honour of your presence at the wedding celebration of: 

*{{BOY_NAME}}*
_The son of Mr. {{BOY_FATHER_NAME}} and Mrs. {{BOY_MOTHER_NAME}}_
& 
*{{GIRL_NAME}}* 
_The daughter of Mr. {{GIRL_FATHER_NAME}} and Mrs. {{GIRL_MOTHER_NAME}}_

Together with the families, we will celebrate the wedding on *{{BROADCAST_WEDDING_DAY}}* at {{BROADCAST_WEDDING_LOCATION}}. 

See you on our wedding day!

Wassalamualaikum Warahmatullahi Wabarakatuh 

_With love,_
*{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & family*`,

  // -------------------------
  // Announcement broadcast
  // -------------------------
  ID_GENERAL_ANNOUNCEMENT: `Yth. Bapak/Ibu/Saudara/i, 
*{{GUEST_NAME}}* 

Mohon klik link website announcement kami dibawah ini sebagai detail pemberitahuan:
{{LINK}}
_*) Alasan keamanan WhatsApp, jika link tidak clickable (tidak berwarna biru), silakan untuk coba klik "Continue / Lanjutkan", balas "YA", atau simpan nomor ini untuk dapat membuka link._

Bersama rahmat dan berkat Tuhan yang telah menyatukan kami dan tanpa mengurangi rasa hormat, kami berniat ingin mengumumkan kepada Bapak/Ibu/Saudara/i mengenai acara pernikahan kami: 

*{{BOY_NAME}}*
_Putra dari Bapak {{BOY_FATHER_NAME}} dan Ibu {{BOY_MOTHER_NAME}}_
& 
*{{GIRL_NAME}}* 
_Putri dari Bapak {{GIRL_FATHER_NAME}} dan Ibu {{GIRL_MOTHER_NAME}}_

Acara pernikahan kami akan dilaksanakan pada hari *{{BROADCAST_WEDDING_DAY}}*.

Terima kasih atas segala doa restu yang telah diberikan kepada kami.

_Kami yang berbahagia,_
*{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & keluarga*`,

  EN_GENERAL_ANNOUNCEMENT: `Dear Mr/Ms/Mrs, 
*{{GUEST_NAME}}* 

Please click the Website Announcement link below for more information about our wedding details: 
{{LINK}}
_*) Due to WhatsApp security reasons, if the link is not clickable (the color is not blue), please try to click "Continue / Lanjutkan", reply "YES", or save this number to open the link._

Together with the grace and blessing of God, we joyfully announce the upcoming marriage of: 

*{{BOY_NAME}}*
_The son of Mr. {{BOY_FATHER_NAME}} and Mrs. {{BOY_MOTHER_NAME}}_
& 
*{{GIRL_NAME}}* 
_The daughter of Mr. {{GIRL_FATHER_NAME}} and Mrs. {{GIRL_MOTHER_NAME}}_

Together with the families, we will celebrate the wedding on {{BROADCAST_WEDDING_DAY}}.

Thank you for your kind words and prayers.

_With love,_
*{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & family*`,

  ID_MUSLIM_ANNOUNCEMENT: `Bismillahirrahmanirrahim 
Assalamualaikum warahmatullahi wabarakatuh 

Yth. Bapak/Ibu/Saudara/i, 
*{{GUEST_NAME}}* 

Mohon klik link website _announcement_ kami dibawah ini sebagai detail pemberitahuan:
{{LINK}}
_*) Alasan keamanan WhatsApp, jika link tidak clickable (tidak berwarna biru), silakan untuk coba klik "Continue / Lanjutkan", balas "YA", atau simpan nomor ini untuk dapat membuka link._

Dengan memohon rahmat dan ridho Allah Subhanahu wa Ta’ala dan tanpa mengurangi rasa hormat, kami berniat ingin mengumumkan kepada Bapak/Ibu/Saudara/i mengenai acara pernikahan kami: 

*{{BOY_NAME}}*
_Putra dari Bapak {{BOY_FATHER_NAME}} dan Ibu {{BOY_MOTHER_NAME}}_
& 
*{{GIRL_NAME}}* 
_Putri dari Bapak {{GIRL_FATHER_NAME}} dan Ibu {{GIRL_MOTHER_NAME}}_

Insha Allah acara pernikahan kami akan dilaksanakan pada hari *{{BROADCAST_WEDDING_DAY}}*.

Terima kasih atas segala doa restu yang telah diberikan kepada kami.

Wassalamualaikum Warahmatullahi Wabarakatuh 

_Kami yang berbahagia,_
*{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & keluarga*`,

  EN_MUSLIM_ANNOUNCEMENT: `Bismillahirrahmanirrahim
Assalamualaikum warahmatullahi wabarakatuh

Dear Mr/Ms/Mrs,
*{{GUEST_NAME}}*

Please click the Website Announcement link below for more information about our wedding details:
{{LINK}}
_*) Due to WhatsApp security reasons, if the link is not clickable (the color is not blue), please try to click "Continue / Lanjutkan", reply "YES", or save this number to open the link._

Together with the blessing of Allah Subhanahu wa Ta’ala, we joyfully announce the upcoming marriage of:

*{{BOY_NAME}}*
_The son of Mr. {{BOY_FATHER_NAME}} and Mrs. {{BOY_MOTHER_NAME}}_
&
*{{GIRL_NAME}}*
_The daughter of Mr. {{GIRL_FATHER_NAME}} and Mrs. {{GIRL_MOTHER_NAME}}_

Together with the families, we will celebrate the wedding on {{BROADCAST_WEDDING_DAY}}.

Thank you for your kind words and prayers.

Wassalamualaikum Warahmatullahi Wabarakatuh

_With love,_ 
*{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & family*`,
};