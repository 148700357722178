import React from 'react';
import { bool, func } from 'prop-types';

import {
  Text,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerHeader,
  DrawerCloseButton,
} from '@chakra-ui/react';

function ModalBroadcast({ onClose, visible }) {

  return (
    <Drawer placement="bottom" onClose={onClose} isOpen={visible}>
      <DrawerOverlay />
      <DrawerContent bgColor='#F0EDE6'>
        <DrawerCloseButton marginTop="12px" />
        <DrawerHeader
          fontFamily="Newsreader"
          borderBottomWidth="1px"
          borderBottomColor='black'
          fontSize="xl"
          fontWeight="bold"
          color="black"
          textAlign="left"
        >
          Apa itu Kalimat Pengantar?
        </DrawerHeader>
        <DrawerBody>
          <Text align="left" fontSize="md" fontFamily='Newsreader' margin="16px 0" color="black">
          <b>Kalimat Pengantar</b> adalah <i>wording</i> atau kata-kata yang digunakan sebagai pesan pengantar pengirim <i>link website invitation/announcement</i> kepada tamu. Kalimat yang dipilihlah yang akan terkirim dan diterima oleh tamu saat Anda mengirim <i>link</i>. 
          </Text>          
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

ModalBroadcast.propTypes = {
  visible: bool.isRequired,
  onClose: func.isRequired,
  onCopyText: func.isRequired,
};


export default ModalBroadcast;